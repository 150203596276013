<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Manage Main Menu</h1>
    </div>
    <div v-loading="loaders.menu" class="my-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <base-input class="width-285 d-inline-block ml-3" label="Search" placeholder="Search..." v-model="request.title"
                    type="search"/>
      </div>
      <el-table
        header-row-class-name="thead-light"
        width="100%"
        :data="menusData"
      >
        <el-table-column width="16">
        </el-table-column>
        <el-table-column label="MENU MANAGER TITLE" :min-width="80">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.menu.title }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" :min-width="20" align="center">
          <template slot-scope="scope">
            <div class="d-flex mt-1 justify-content-center">
              <span class="pr-3">Off</span>
              <base-switch v-model="scope.row.status" @input="updateMenuStatus(scope.row.id, scope.row.status)"
                           :off-text="''" :on-text="''"/>
              <span class="pl-3">On</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton"
import {Table, TableColumn} from "element-ui"

export default {
  name: "Menus",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BackButton,
  },
  data() {
    return {
      loaders: {
        menu: false
      },
      response: {
        menus: []
      },
      request: {
        title: ''
      },
    }
  },
  computed: {
    menusData() {
      return this.response.menus.filter(data => !this.request.title || data.menu.title.toLowerCase().includes(this.request.title.toLowerCase()))
    }
  },
  mounted() {
    this.getAllMenus()
  },
  methods: {
    /**
     * Get All Menu's
     */
    getAllMenus() {
      let vm = this
      vm.loaders.menu = true
      vm.$store.dispatch('ManageMenuModule/_getMenusListing')
        .then(response => {
          vm.response.menus = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Manage Menu',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.menu = false
        })
    },

    /**
     * Update Menu Status
     * @param id
     * @param status
     */
    updateMenuStatus(id, status) {
      let vm = this
      vm.loaders.menu = true
      const payload = {
        id, status
      }
      vm.$store.dispatch('ManageMenuModule/_toggleMenuStatus', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Menu Status Updated Successfully!'
          });
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Menu Status',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.menu = false
        })
    },
  }
}
</script>

<style scoped>

</style>
